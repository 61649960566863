import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c('p',{staticClass:"display-1 text--primary"},[_vm._v(" "+_vm._s(_vm.$t("menu.profiles"))+" ")])])],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"search":_vm.search,"items":_vm.roles,"no-results-text":_vm.$t('general.ui.not_search_found'),"no-data-text":_vm.$t('general.ui.not_data_found'),"loading-text":_vm.$t('general.notification.loadData'),"footer-props":{
      itemsPerPageText: _vm.$t('general.table.itemsPerPageText'),
      pageText: '{0}-{1} ' + _vm.$t('general.table.pageText') + ' {2}',
    }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c(VToolbar,{attrs:{"flat":"","color":"white"}},[_c(VRow,{staticClass:"d-flex justify-space-between align-baseline"},[_c(VCol,{staticClass:"pl-0",attrs:{"lg":"4","md":"4","sm":"6","xl":"4","cols":"6"}},[_c(VTextField,{attrs:{"onkeypress":"return (event.charCode != 34)","dense":"","filled":"","rounded":"","color":"secondary","append-icon":"mdi-magnify","label":_vm.$t('general.titles.filter'),"clearable":true,"single-line":"","hide-details":""},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==86)return null;return _vm.replaceSearch.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==86)return null;if(!$event.ctrlKey)return null;return _vm.replaceSearch.apply(null, arguments)}]},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VSpacer),_c(VCol,{staticClass:"text-end",attrs:{"lg":"4","md":"4","sm":"4","xl":"4","cols":"4"}},[(_vm.check([{ domain: 'Role', permisions: ['Write'] }]))?_c(VBtn,{staticStyle:{"border-radius":"1.5em"},attrs:{"small":"","tile":"","elevation":0,"color":"#d8d8d896"},on:{"click":_vm.showAdd}},[_c(VIcon,{attrs:{"small":"","color":"#6D6D6D","left":""}},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$t("general.buttons.new"))+" ")],1):_vm._e()],1)],1)],1)]},proxy:true},{key:"item.action",fn:function({ item }){return [_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(
              item.canDeleted &&
                _vm.check([{ domain: 'Role', permisions: ['Update'] }])
            )?_c(VIcon,_vm._g({staticClass:"mr-2",attrs:{"color":"neuter","small":""},on:{"click":function($event){return _vm.editItem(item)}}},on),[_vm._v(" mdi-square-edit-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("general.buttons.edit"))+" "),_c('span',{staticStyle:{"font-size":"12px","color":"#AAAAAA"}},[_vm._v(" "+_vm._s(_vm.$t("menu.profiles"))+" ")])])]),_c(VTooltip,{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [(
              item.canDeleted &&
                _vm.check([{ domain: 'Role', permisions: ['Delete'] }])
            )?_c(VIcon,_vm._g({attrs:{"color":"neuter","small":""},on:{"click":function($event){return _vm.deleteItemDialog(item)}}},on),[_vm._v(" mdi-trash-can-outline ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$t("general.buttons.delete"))+" "),_c('span',{staticStyle:{"font-size":"12px","color":"#AAAAAA"}},[_vm._v(" "+_vm._s(_vm.$t("menu.profiles"))+" ")])])])]}}])}),_c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,{attrs:{"color":"white"}},[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])]),_c(VDivider),_c(VCardText,[_c(VContainer,[_c(VForm,{ref:"form1",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{staticClass:"required",attrs:{"onkeypress":"return (event.charCode != 34)","color":"secondary","required":"","rules":_vm.requireAndMaxRules(_vm.max),"counter":_vm.max},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==86)return null;return _vm.replaceName.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==86)return null;if(!$event.ctrlKey)return null;return _vm.replaceName.apply(null, arguments)}]},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('roles.fields.name'))+" "),_c('strong',{staticClass:"red--text"},[_vm._v("*")])]},proxy:true}]),model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1),_c(VRow,_vm._l((_vm.order(
                        _vm.editedItem.permissionByDomainDTOList
                      )),function(editedModule,i){return (
                        _vm.editedItem.permissionByDomainDTOList.length &&
                          editedModule.domain
                      )?_c(VCol,{key:i,attrs:{"cols":"6"}},[_vm._l((_vm.order(_vm.modules)),function(module){return [(module.domain === editedModule.domain)?_c(VSelect,{key:'select' + editedModule.module,attrs:{"color":"secondary","item-color":"secondary","deletable-chips":"","label":_vm.$t(`modules.name.${module.domain}`),"items":_vm.orderDatas(module.permissions),"small-chips":"","multiple":""},model:{value:(editedModule.permissions),callback:function ($$v) {_vm.$set(editedModule, "permissions", $$v)},expression:"editedModule.permissions"}}):_vm._e()]})],2):_vm._e()}),1)],1)],1)],1),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","tile":"","outlined":"","color":"gray"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t("general.buttons.cancel"))+" ")]),_c(VBtn,{attrs:{"small":"","tile":"","outlined":"","color":"secondary","disabled":!_vm.valid},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.$t("general.buttons.save"))+" ")])],1)],1)],1)],1),_c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{attrs:{"persistent":"","width":"600px"},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(_vm._s(_vm.title))]),_c(VCardText,[_vm._v(_vm._s(_vm.dialogText))]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","text":"","color":"gray"},on:{"click":_vm.dialogClose}},[_vm._v(" "+_vm._s(_vm.$t("general.buttons.cancel"))+" ")]),_c(VBtn,{attrs:{"small":"","text":"","color":"secondary"},on:{"click":_vm.deleteItem}},[_vm._v(" "+_vm._s(_vm.$t("general.buttons.confirm"))+" ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }